<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="additionalInfoCategoriesList"
        :loading="loading"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="getAdditionalInfoCategoriesTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Категорії</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <router-link
              v-if="!isTooltipsPage"
              :to="{ name: 'InfoConstructorArticlesList', params: { id: currentCompanyId } }"
              class="text-decoration-none v-toolbar__title"
            >
              Статті всіх категорій
            </router-link>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <v-dialog @click:outside="close" v-model="dialog" max-width="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on">Додати категорію</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.name.uk"
                          label="Назва категорії (UK)"
                          @input="$v.editedItem.name.uk.$touch()"
                          @blur="$v.editedItem.name.uk.$touch()"
                          :error-messages="categoryErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.name.en" label="Назва категорії (EN)"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.name.ru" label="Назва категорії (RU)"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model.number="editedItem.category_type"
                          :items="articlesCategoriesTypesDictionary"
                          item-text="name"
                          item-value="value"
                          label="Тип категорії"
                          @input="$v.editedItem.category_type.$touch()"
                          @blur="$v.editedItem.category_type.$touch()"
                          :error-messages="categoryTypeErrors"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Категорію буде видалено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
                  <v-btn text @click="closeDelete">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-if="item.status !== null && item.status !== 1"
                :style="{ color: getListStatusColor(item.status) }"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.id }}
              </span>
              <span v-else v-bind="attrs" v-on="on">{{ item.id }}</span>
            </template>
            <span>{{ getListStatusTitle(item.status) }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: 'InfoConstructorArticlesList',
              params: { id: currentCompanyId },
              query: { categoryId: item.id },
            }"
            class="text-decoration-none"
          >
            {{ item.name.uk }}
          </router-link>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on"> mdi-pencil-outline </v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on"> mdi-delete-outline </v-icon>
            </template>
            <span>Видалити</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData"> Оновити </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { ADDITIONAL_INFO_CATEGORY_SCHEMA } from '@/const/apiSchemas'
import cloneDeep from '@/mixins/cloneDeep'
import resetTableData from '@/mixins/resetTableData'
import { mergeObjectsDeep } from '@/helpers/mergeObjects'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import AwaitingSearch from '@/mixins/constructor/awaitingSearch'
import BackButton from '@/components/common/BackButton.vue'
import sortUtils from '@/mixins/sortUtils'

export default {
  name: 'InfoConstructorCategoriesList',
  components: { BackButton },

  mixins: [resetTableData, cloneDeep, validationMixin, ConvertStatusesTypes, AwaitingSearch, sortUtils],

  validations: {
    editedItem: {
      name: {
        uk: { required },
      },
      category_type: { required },
    },
  },

  data: () => ({
    ADDITIONAL_INFO_CATEGORY_SCHEMA: ADDITIONAL_INFO_CATEGORY_SCHEMA,
    search: '',
    dialog: false,
    dialogDelete: false,
    loading: false,
    headers: [
      { text: 'ID', align: 'start', sortable: true, value: 'id' },
      { text: 'Назва категорії', value: 'name', sortable: true },
      // { text: 'Назва категорії (EN)', value: 'name.en', sortable: false },
      // { text: 'Назва категорії (RU)', value: 'name.ru', sortable: false },
      { text: '', value: 'actions', sortable: false, width: '125px', align: 'center' },
    ],
    sortBy: 'id',
    sortDesc: false,
    editedIndex: -1,
    options: {},
    clickedCategoryId: null,
    additionalInfoCategoriesList: [],
    editedItem: {},
    defaultItem: {},
    preselectedCategoryType: 1,
    isPageLoaded: false,
  }),

  computed: {
    ...mapGetters('infoConstructor', ['getAdditionalInfoCategoriesTotalCount']),
    ...mapState('infoConstructor', ['additionalInfoCategories']),
    ...mapState('dictionaries', ['articlesCategoriesTypesDictionary']),

    formTitle() {
      return this.editedIndex === -1 ? 'Додати категорію' : 'Редагувати категорію'
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    isTooltipsPage() {
      return this.$route.name === 'InfoConstructorTooltipsCategoriesList'
    },
    categoryErrors() {
      const errors = []
      if (!this.$v.editedItem.name.uk.$dirty) return errors
      !this.$v.editedItem.name.uk.required && errors.push('Це поле обов"язкове')
      return errors
    },
    categoryTypeErrors() {
      const errors = []
      if (!this.$v.editedItem.category_type.$dirty) return errors
      !this.$v.editedItem.category_type.required && errors.push('Це поле обов"язкове')
      return errors
    },
  },

  watch: {
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
    isTooltipsPage: {
      handler(val) {
        val ? (this.preselectedCategoryType = 2) : (this.preselectedCategoryType = 1)
        if (this.isPageLoaded) {
          this.clearData()
          this.paginateTo()
        }
      },
      immediate: true,
    },
  },

  created() {
    this.clone()
  },

  mounted() {
    this.isPageLoaded = true
  },

  beforeDestroy() {
    this.clearData()
  },

  methods: {
    ...mapActions('infoConstructor', [
      'loadAdditionalInfoCategories',
      'createNewAdditionalInfoCategory',
      'updateSelectedAdditionalInfoCategory',
      'deleteSelectedAdditionalInfoCategory',
    ]),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapMutations('infoConstructor', ['SET_ADDITIONAL_INFO_ARTICLES', 'SET_ADDITIONAL_INFO_CATEGORIES']),

    async initialize() {
      this.additionalInfoCategoriesList = [...this.additionalInfoCategories]
    },

    clone() {
      this.editedItem = { ...this.cloneObjectDeep(this.ADDITIONAL_INFO_CATEGORY_SCHEMA) }
      this.defaultItem = { ...this.cloneObjectDeep(this.ADDITIONAL_INFO_CATEGORY_SCHEMA) }
    },

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const params = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
          search: this.search,
          categoryType: this.preselectedCategoryType,
        }
        await this.loadAdditionalInfoCategories(params)
        await this.initialize()
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },

    editItem(item) {
      this.editedIndex = this.additionalInfoCategoriesList.indexOf(item)
      this.editedItem = mergeObjectsDeep(this.editedItem, item)
      this.clickedCategoryId = item.id
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.additionalInfoCategoriesList.indexOf(item)
      this.clickedCategoryId = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      await this.deleteSelectedAdditionalInfoCategory(this.clickedCategoryId)
      this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.clone()
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.clone()
        this.editedIndex = -1
      })
    },

    async save() {
      this.$v.$touch()
      if (this.$v.$anyError) return
      this.loading = true
      if (this.editedIndex > -1) {
        const payload = {
          categoryId: this.clickedCategoryId,
          updatedCategory: this.editedItem,
        }
        await this.updateSelectedAdditionalInfoCategory(payload)
      } else {
        await this.createNewAdditionalInfoCategory(this.editedItem)
      }
      this.close()
      await this.paginateTo()
      this.loading = false
    },

    clearData() {
      this.SET_ADDITIONAL_INFO_ARTICLES([])
      this.SET_ADDITIONAL_INFO_CATEGORIES([])
    },
  },
}
</script>

<style scoped></style>
